<template>

    <div class="w-full h-full px-3 relative flex flex-col">

        <div class="absolute top-0 left-0 h-56 w-full bg-red"></div>

        <div class="h-40 w-full flex flex-col justify-center items-center z-20">

            <div class="h-20 shadow-pamesa w-20 rounded-full bg-gray text-white flex justify-center items-center font-light text-5xl"> {{user.name.substr(0,1)}}</div>
            <span class="text-lg font-medium mt-2" :class="'text-module-'+mode">{{user.name}} {{user.surname1}}</span>
            <span class="text-sm" :class="'text-module-'+mode">{{user.email}}</span>

        </div>

        <div class="h-auto w-full z-20">

            <div class="h-auto w-full rounded-lg shadow_box flex flex-col p-2" :class="'bg-module-'+mode">
                
                <div class="h-12 px-2 flex flex-row justify-start items-center">

                    <span class="text-md font-medium mr-auto" :class="'text-dfont-'+mode">{{ $t('darkmode') }}</span>

                    <el-switch v-model="modeact" active-value="darkmode" inactive-value="whitemode" active-color="#13ce66"/>

                </div>

                <div class="h-12 px-2 flex flex-row justify-start items-center border-t border-opacity-25" :class="'border-font_gray-'+mode" @click="opencambiarpass()">

                    <span class="text-md font-medium mr-auto" :class="'text-dfont-'+mode">{{$t('changePassword')}}</span>

                    <i class="mdi text-xl text-red font-medium" :class="{'mdi-chevron-down': !cambiarpass, 'mdi-chevron-up': cambiarpass}" style="transition: .3s;"></i>

                </div>

                <div class="overflow-hidden"  :class="{'h-0': !cambiarpass, 'h-56': cambiarpass}" style="transition: .3s;">

                    <div v-if="cambiarpass" class="h-full w-full flex flex-col flex-center items-center" >

                        <Request
                        model="Changeme"
                        action="update"
                        :form="form"
                        @success="onSuccess"
                        @error="onError"
                        v-slot="{ request, loading }" >

                            <div v-if="!loading" class="h-full w-full flex flex-col justify-center items-center mt-2 px-2">

                                <div class="h-auto w-full flex flex-col justify-center items-start">
                                    <span class="text-sm mb-1" :class="'text-dfont-'+mode">{{$t('newPassword')}}</span>
                                    <div class="h-8 w-full">
                                        <div class="h-full w-full bg-lightgray rounded-lg flex flex-row justify-between items-center px-2 relative">
                                            <input :type="typepassword" :placeholder="$t('password')" class="w-full bg-lightgray text-sm" v-model="form.password">
                                            <div class="h-10 w-10 flex flex-row justify-center items-center" @click="typepass()">
                                                <i v-if="typepassword == 'password'" class="mdi mdi-eye text-dfont"></i>
                                                <i v-if="typepassword == 'text'" class="mdi mdi-eye-off text-dfont"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="h-auto w-full mt-4 flex flex-col justify-center items-start">
                                    <span class="text-sm mb-1" :class="'text-dfont-'+mode">{{$t('repeatPassword')}}</span>
                                    <div class="h-8 w-full">
                                        <div class="h-full w-full bg-lightgray rounded-lg flex flex-row justify-between items-center px-2" >
                                            <input :type="typepassword2" :placeholder="$t('repeatPassword')" class="w-full bg-lightgray text-sm" v-model="newpass2">
                                            <div class="h-10 w-10 flex flex-row justify-center items-center" @click="typepass2()">
                                                <i v-if="typepassword2 == 'password'" class="mdi mdi-eye text-dfont"></i>
                                                <i v-if="typepassword2 == 'text'" class="mdi mdi-eye-off text-dfont"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="form.password === newpass2 && form.password !== ''" @click="changepass(request)"
                                    class="h-auto w-full p-2 bg-red rounded-lg shadow-card mb-2 flex flex-row justify-center items-center cursor-pointer mt-5">
                                    <span class="text-sm text-white">{{$t('change')}}</span>
                                </div>

                                <div v-else
                                    class="h-auto w-full p-2 bg-gray rounded-lg shadow-card mb-2 flex flex-row justify-center items-center cursor-pointer mt-5">
                                    <span class="text-sm text-white">{{$t('change')}}</span>
                                </div>

                            </div>

                        </Request>

                    </div>

                </div>

                <div class="h-12 px-2 flex flex-row justify-start items-center border-t border-opacity-25" :class="'border-font_gray-'+mode" @click="openChangeLanguage()">

                    <span class="text-md font-medium mr-auto" :class="'text-dfont-'+mode">{{$t('changeLanguage')}}</span>

                    <i class="mdi text-xl text-red font-medium" :class="{'mdi-chevron-down': !changeLanguage, 'mdi-chevron-up': changeLanguage}" style="transition: .3s;"></i>

                </div>

                <div class="overflow-auto"  :class="{'h-0': !changeLanguage, 'h-32 overflow-auto': changeLanguage}" style="transition: .3s;">
                
                    <div v-for="(el,index) in lang" :key="index" class="h-10 w-full flex flex-row justify-start items-center px-4" :class="{'bg-red bg-opacity-25': el.iso == language }" @click="language = el.iso">

                        <span v-if="el.iso != language" class="text-sm font-medium" :class="'text-dfont-'+mode">{{ el[getName].language }}</span>
                        <span v-else class="text-sm font-bold text-red">{{ el[getName].language }}</span>

                    </div>
                
                </div>

            </div>

        </div>

        <!-- <div class="h-auto w-full rounded-lg shadow_box flex flex-col p-2 mb-6" :class="'bg-module-'+mode">

            <div class="h-auto mt-6 w-full flex flex-row justify-center items-center">
                <div  class="h-32 shadow-card w-32 rounded-full bg-gray text-white flex justify-center items-center font-light text-5xl"> {{user.name.substr(0,1)}}</div>
            </div>

            <div class="h-auto mt-2 flex flex-col justify-center items-center">
                <span class="text-lg font-medium" :class="'text-dfont-'+mode">{{user.name}} {{user.surname1}}</span>
                <span class="text-sm text-darkgray">{{user.email}}</span>
            </div>

            <div v-if="user.email = 'admin@mail.zeus.vision'" class="h-20 flex flex-row justify-center items-center">
                
                <span class="mr-8" :class="'text-dfont-'+mode">Dark mode</span>
                <el-switch v-model="mode" active-value="darkmode" inactive-value="whitemode" active-color="#13ce66"/>

            </div>

            <div class="h-auto flex flex-row justify-center items-center mb-4">

                <div class="h-auto w-40 px-2 py-2 mx-auto mt-6 bg-red rounded-lg shadow-card flex flex-row justify-center items-center cursor-pointer" :class="{'bg-red':!cambiarpass, 'bg-bad':cambiarpass}" style="transition: all .3s" @click="opencambiarpass()">
                    <span v-if="!cambiarpass" class="text-sm text-white">{{$t('changePassword')}}</span>
                    <span v-if="cambiarpass" class="text-sm text-white">{{$t('cancel')}}</span>
                </div>
                
                <div class="h-auto w-40 px-2 py-2 mx-auto mt-6 bg-red rounded-lg shadow-card flex flex-row justify-center items-center cursor-pointer" :class="{'bg-red':!changeLanguage, 'bg-bad':changeLanguage}" style="transition: all .3s" @click="openChangeLanguage()">
                    <span v-if="!changeLanguage" class="text-sm text-white">{{$t('changeLanguage')}}</span>
                    <span v-if="changeLanguage" class="text-sm text-white">{{$t('close')}}</span>
                </div>

            </div>


            <div class="overflow-hidden"  :class="{'h-0': !cambiarpass, 'h-48': cambiarpass}" style="transition: .5s;">
                <div v-if="cambiarpass" class="h-full flex flex-col flex-start items-center" >
                    <Request
                            model="Changeme"
                            action="update"
                            :form="form"
                            @success="onSuccess"
                            @error="onError"
                            v-slot="{ request, loading }" >
                        <div v-if="!loading" class="h-auto w-full flex flex-col justify-center items-center mt-2">
                            <div class="h-auto px-2 flex flex-col justify-center items-start">
                                <span class="text-sm text-dfont mb-1">{{$t('newPassword')}}</span>
                                <div class="h-8 w-full">
                                    <div class="h-full w-full bg-lightgray rounded-lg flex flex-row justify-between items-center px-2 relative">
                                        <input :type="typepassword" :placeholder="$t('password')" class="w-full bg-lightgray text-sm" v-model="form.password">
                                        <div class="h-10 w-10 flex flex-row justify-center items-center" @click="typepass()">
                                            <i v-if="typepassword == 'password'" class="mdi mdi-eye text-dfont"></i>
                                            <i v-if="typepassword == 'text'" class="mdi mdi-eye-off text-dfont"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="h-auto px-2 mt-3 flex flex-col justify-center items-start">
                                <span class="text-sm text-dfont mb-1">{{$t('repeatPassword')}}</span>
                                <div class="h-8 w-full">
                                    <div class="h-full w-full bg-lightgray rounded-lg flex flex-row justify-between items-center px-2" >
                                        <input :type="typepassword2" :placeholder="$t('repeatPassword')" class="w-full bg-lightgray text-sm" v-model="newpass2">
                                        <div class="h-10 w-10 flex flex-row justify-center items-center" @click="typepass2()">
                                            <i v-if="typepassword2 == 'password'" class="mdi mdi-eye text-dfont"></i>
                                            <i v-if="typepassword2 == 'text'" class="mdi mdi-eye-off text-dfont"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="form.password === newpass2 && form.password !== ''" @click="changepass(request)"
                                 class="h-auto w-auto p-2 bg-red rounded-lg shadow-card mb-2 flex flex-row justify-center items-center cursor-pointer mt-3">
                                <span class="text-sm text-white">{{$t('change')}}</span>
                            </div>
                        </div>
                    </Request>
                </div>
            </div>

            <div v-if="errormodal" class="absolute h-full w-full top-0 left-0 flex col justify-center items-center" style="backdrop-filter:blur(3px)">
                <div class="h-auto w-4/5 bg-white rounded-lg shadow-card flex flex-col overflow-hidden">
                    <div class="h-10 w-full bg-red flex flex-row justify-between items-center px-2">
                        <span class="text-white font-semibold">{{$t('unsecuredPassword')}}</span>
                        <div class="h-10 w-10 flex flex-col justify-center items-center" @click="errormodal = !errormodal">
                            <i class="mdi mdi-close text-white"></i>
                        </div>
                    </div>
                    <div class="flex-1 flex flex-col justify-center items-start p-4">
                        <span class="text-dfont font-semibold mb-2"><span class="font-bold">·</span> {{$t('passwordMustBe')}}</span>
                        <span class="text-dfont"><span class="font-bold">·</span> {{$t('betweenCharacters')}}</span>
                        <span class="text-dfont"><span class="font-bold">·</span> {{$t('leastOneDigit')}}</span>
                        <span class="text-dfont"><span class="font-bold">·</span> {{$t('leastLowerAndUpperCase')}}</span>
                    </div>
                </div>
            </div>

            <div class="flex justify-center items-center flex-col overflow-hidden" :class="{'h-0': !changeLanguage, 'h-48': changeLanguage}" style="transition: .5s;">
                <div v-if="changeLanguage" class="h-full flex flex-col flex-start items-center" >
                    <div class="h-auto w-full flex flex-col justify-center items-center mt-2">
                        <div class="container h-full flex-1 flex flex-row justify-start items-center truncate">
                            <section v-if="this.language === 'en-EN'">
                                <b-field>
                                    <div class="h-full bg-lightgray rounded-lg flex flex-row justify-between items-center px-2">
                                        <select name="language" v-model="language" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                            <option v-for="(el,index) in lang" :key="index" :value="el.iso" :class="'text-dfont-'+mode">{{el.name.language}}</option>
                                        </select>
                                    </div>
                                </b-field>
                            </section>
                            <section v-else-if="this.language === 'fr-FR'">
                                <b-field>
                                    <div class="h-full bg-lightgray rounded-lg flex flex-row justify-between items-center px-2">
                                        <select name="language" v-model="language" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                            <option v-for="(el,index) in lang" :key="index" :value="el.iso" :class="'text-dfont-'+mode">{{el.nom.language}}</option>
                                        </select>
                                    </div>
                                </b-field>
                            </section>
                            <section v-else-if="this.language === 'it-IT'">
                                <b-field>
                                    <div class="h-full bg-lightgray rounded-lg flex flex-row justify-between items-center px-2">
                                        <select name="language" v-model="language" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                            <option v-for="(el,index) in lang" :key="index" :value="el.iso" :class="'text-dfont-'+mode">{{el.nome.language}}</option>
                                        </select>
                                    </div>
                                </b-field>
                            </section>
                            <section v-else-if="this.language === 'de-DE'">
                                <b-field>
                                    <div class="h-full bg-lightgray rounded-lg flex flex-row justify-between items-center px-2">
                                        <select name="language" v-model="language" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                            <option v-for="(el,index) in lang" :key="index" :value="el.iso" :class="'text-dfont-'+mode">{{el.deutscherName.language}}</option>
                                        </select>
                                    </div>
                                </b-field>
                            </section>
                            <section v-else>
                                <b-field>
                                    <div class="h-full bg-lightgray rounded-lg flex flex-row justify-between items-center px-2">
                                        <select name="language" v-model="language" class="h-full w-full" :class="'bg-box-'+mode+' text-dfont-'+mode">
                                            <option v-for="(el,index) in lang" :key="index" :value="el.iso" :class="'text-dfont-'+mode">{{el.nombre.language}}</option>
                                        </select>
                                    </div>
                                </b-field>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

    </div>

</template>

<script>
    import basiclinechart from '../../components/basiclinechart.vue';
    import { All, Request } from '@/api/components';
    import { state, actions } from '@/store';

    export default {
        components:{
            basiclinechart,
            All,
            Request
        },
        data(){
            return{
                language: localStorage.getItem('locale'),
                lang: [
                    {"id":1,"nombre":{"language":"Inglés"}, "name":{"language":"English"}, "nom": {"language": "Anglais"}, "nome": {"language": "Inglese"}, "deutscherName": {"language": "Englisch"}, "iso":"en-EN"},
                    {"id":2,"nombre":{"language":"Español"}, "name":{"language":"Spanish"}, "nom": {"language": "Espagnole"}, "nome": {"language": "Spagnolo"}, "deutscherName": {"language": "Spanisch"}, "iso":"es-ES"},
                    {"id":3,"nombre":{"language":"Frances"}, "name":{"language":"French"}, "nom": {"language": "Français"}, "nome": {"language": "Francese"}, "deutscherName": {"language": "Französisch"}, "iso":"fr-FR"},
                    {"id":4,"nombre":{"language":"Italiano"}, "name":{"language":"Italian"}, "nom": {"language": "Italien"}, "nome": {"language": "Italiano"}, "deutscherName": {"language": "Italienisch"}, "iso":"it-IT"},
                    {"id":5,"nombre":{"language":"Aleman"}, "name":{"language":"German"}, "nom": {"language": "Allemand"}, "nome": {"language": "Tedesco"}, "deutscherName": {"language": "Deutsch"}, "iso":"de-DE"},
                ],
                cambiarpass: false,
                changeLanguage: false,
                newpass2:'',
                form: {
                    Id: state.user.id,
                    password: ''
                },
                errormodal:false,
                typepassword: 'password',
                typepassword2: 'password',
                modeact: state.mode
            }
        },
        watch: {
            language: function() {
                this.changeLocale()
                //location.reload()
            },
            modeact(n,o){
                if(n == 'whitemode'){
                    document.querySelector("meta[name='theme-color']").content = "#F2F2F2";
                } else {
                    document.querySelector("meta[name='theme-color']").content = "#1F232F";
                }
                actions.setMode(n)
            }
        },
        methods:{
            changeLocale() {
                this.$i18n.locale = this.language
                actions.setLanguage(this.language)

                var language = '';

                if (localStorage.getItem('locale') === 'es-ES') {
                    language = 'es'
                } else {
                    if (localStorage.getItem('locale') === 'fr-FR')
                        language = 'fr'
                    else
                    if (localStorage.getItem('locale') === 'it-IT')
                        language = 'it'
                    else
                    if (localStorage.getItem('locale') === 'de-DE')
                        language = 'de'
                    else
                        language = 'en-gb'
                }
                this.$moment.locale(language)

            },
            toFilters(){
                this.$router.push('filters')
            },
            opencambiarpass(){
                this.cambiarpass = !this.cambiarpass
            },
            openChangeLanguage() {
                this.changeLanguage = !this.changeLanguage
            },
            async onSuccess(){
                this.$message({
                    message: 'Contraseña Actualizada',
                    type: 'success'
                });
                this.cambiarpass =! this.cambiarpass
                const user = await Auth.me();
                actions.setUser(user);
            },
            validatePass(){
                if (this.form.password == this.newpass2){
                    let passRegex = /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;
                    if (passRegex.test(this.form.password)) { return true }
                    else { return false }
                } else { return false }
            },
            changepass(request){
                if (this.validatePass()) { request()
                } else { this.errormodal = !this.errormodal }
            },
            onError(){
                this.$message.error(state.messageError);
            },
            typepass(){
                if (this.typepassword == 'password') { this.typepassword = 'text'
                } else { this.typepassword = 'password' }
            },
            typepass2(){
                if (this.typepassword2 == 'password') { this.typepassword2 = 'text'
                } else { this.typepassword2 = 'password' }
            }
        },
        computed:{
            user(){
                return state.user
            },
            mode(){
                return state.mode
            },
            getName(){

                switch (this.language) {
                    case 'es-ES':
                        return 'nombre'
                    case 'en-EN':
                        return 'name'
                    case 'fr-FR':
                        return 'nom'
                    case 'it-IT':
                        return 'nome'
                    case 'de-DE':
                        return 'deutscherName'
                }

            }
        },
        mounted(){
            this.mode = state.mode
        }
    }
</script>